.submenu:hover {
  border-bottom: 2px solid #2d3748;
}

.users {
  background: #1488cc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2b32b2,
    #1488cc
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2b32b2,
    #1488cc
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.admins {
  background: #799f0c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #acbb78,
    #799f0c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #acbb78,
    #799f0c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.proposars {
  background: #e65c00; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f9d423,
    #e65c00
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f9d423,
    #e65c00
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.services {
  background: #cc2b5e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #753a88,
    #cc2b5e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #753a88,
    #cc2b5e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.sellers {
  background: #536976; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #292e49,
    #536976
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #292e49,
    #536976
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.carrousel {
  margin-top: 100px;
}
button.selected {
  background-color: rgba(255, 134, 0, 0.5);
  color: white;
}

button.unselected {
  background-color: rgba(255, 134, 0);
  color: black;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.bounce {
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.flicker {
  animation-name: flicker;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Estilo general del calendario */
.react-calendar {
  border: unset;
  background-color: white;
  font-family: "DM Sans", sans-serif;
  margin-top: 10px;
  /*   padding-left: 30px;
  padding-right: 30px; */
  width: 300px;
}

/* Oculta los botones de navegación extra */
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

/* Estilos de navegación */
.react-calendar__navigation {
  align-items: center;
}

/* Estilo de los botones de navegación */
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  background-color: rgba(255, 134, 0);
  border-radius: 10px;
  min-width: 34px !important;
  margin-bottom: 15px;
  height: 34px !important;
  color: rgb(5, 7, 6);
}

/* Estilos de la etiqueta de navegación */
.react-calendar__navigation__label {
  font-weight: 700 !important;
  font-size: 18px;
  color: rgba(255, 134, 0);
  padding-left: 5px;
  padding-right: 5px;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__label:focus {
  background-color: unset !important;
  border-radius: 10px;
}

/* Estilos de los días de la semana */
.react-calendar__month-view__weekdays {
  background-color: rgba(255, 134, 0, 0.2);
  color: rgba(255, 134, 0);
  border-radius: 10px;
  margin-bottom: 6px;
}

/* Estilos del día actual */

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: rgba(255, 134, 0, 0.6) !important;
}

/* Ajuste de opacidad para el fondo al pasar el ratón */
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: rgba(255, 134, 0);
}

/* Estilos del día activo (seleccionado) */
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(255, 134, 0);
}

/* Estilos del rango de días seleccionados */
.react-calendar__tile--range,
.react-calendar__tile--range:enabled:hover,
.react-calendar__tile--range:enabled:focus {
  background: #f4f7fe;
  color: rgba(255, 134, 0);
  border-radius: 0px;
}

/* Estilos del primer día del rango seleccionado */
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:enabled:hover,


/* Estilos del último día del rango seleccionado */
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:enabled:focus {
  background: rgba(255, 134, 0);
  color: rgb(2, 2, 2);
}

/* Estilos de los días del mes */
.react-calendar__month-view__days__day {
  background-color: rgba(255, 134, 0);
}

/* Cambiar el estilo de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 3px; /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(
    255,
    134,
    0,
    0.4
  ); /* Color de fondo del 'pulgón' de la barra de desplazamiento */
  border-radius: 5px; /* Bordes redondeados del 'pulgón' */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color de fondo de la pista de la barra de desplazamiento */
}

/* Estilo cuando el ratón está sobre la barra de desplazamiento */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 134, 0);
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 480px;
  height: 30px;
  border-radius: 20px;
  background: linear-gradient(rgba(255, 134, 0) 0 0) 0/0% no-repeat lightblue;
  animation: l2 2s infinite steps(10);
}
@keyframes l2 {
  100% {
    background-size: 110%;
  }
}
